import { createFetch } from "@vueuse/core";
const baseUrlFCH =
  process.env.VUE_APP_IS_BRANCH_PROD == "1"
    ? process.env.VUE_APP_ENDPOINT_FUNCTIONS_FCH_PROD
    : process.env.VUE_APP_ENDPOINT_FUNCTIONS_FCH_TEST;
console.log(baseUrlFCH);
export const useFetchJSONFCH = createFetch({
  baseUrl: baseUrlFCH,
  combination: "overwrite",
  options: {
    immediate: false,
    // beforeFetch in pre-configured instance will only run when the newly spawned instance do not pass beforeFetch
    async beforeFetch({ options }) {
      console.log(options);
      options.headers = {
        ...options.headers,
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("tokenAdb2c")}`,
      };

      return { options };
    },
  },
});
