import axios from "axios";
import { alertFailed } from "@/composables/swAlert";
import { msalInstance } from "@/authConfig";
import router from "@/router";
import store from "../store/index";
import useSSOFunction from "@/composables/composableGestioneSSO";

export const baseUrlApiApp =
  process.env.VUE_APP_IS_BRANCH_PROD == "1"
    ? process.env.VUE_APP_ENDPOINT_API_PROD
    : process.env.VUE_APP_ENDPOINT_API_TEST;

export const dbManagApp = axios.create({
  baseURL: baseUrlApiApp,
});
dbManagApp.interceptors.request.use((config) => {
  const token = localStorage.getItem("tokenAdb2c");
  config.headers["Authorization"] = "Bearer " + token;
  return config;
});

export const requestAppPost = async (
  endpoint: string,
  payload: Record<string, unknown>,
  noAlert
) => {
  return dbManagApp
    .post(endpoint, payload)
    .then((res) => {
      store.commit("setManutenzione", false);

      // throw new Error("525");
      if (res.status == 401) {
        // msalInstance.logoutRedirect();
        throw new Error("401");
      }
      if (res.status == 525) {
        // msalInstance.logoutRedirect();
        throw new Error("525");
      }

      return {
        ...res,
      };
    })
    .catch((e) => {
      const { logoutFunction } = useSSOFunction();
      console.log(e.message, e.response, e == "525");
      if (e.message == "525") {
        console.log(e.message, e == "525");
        store.commit("setManutenzione", true);
        router.push("/manutenzione");
        const custom_response = { status: 525 };
        return {
          ...custom_response,
        };
      }
      if (e == "401") {
        alertFailed(
          "Sessione Scaduta. Per continuare, effettuare nuovamente il login"
        ).then(() => logoutFunction());
        return;
      }

      if (e.response.data.message == "Utente non autorizzato") {
        logoutFunction();
        return;
      }

      return {
        ...e.response,
      };
    });
};

export const requestBasePost = async (
  endpoint: string,
  payload: Record<string, unknown>
) => {
  return dbManagApp
    .post(endpoint, payload)
    .then((res) => {
      store.commit("setManutenzione", false);
      if (res.status == 401) {
        // msalInstance.logoutRedirect();
        throw new Error("401");
      }
      if (res.status == 525) {
        // msalInstance.logoutRedirect();
        throw new Error("525");
      }
      return {
        ...res,
      };
    })
    .catch((e) => {
      const { logoutFunction } = useSSOFunction();
      if (e == "401") {
        alertFailed(
          "Sessione Scaduta. Per continuare, effettuare nuovamente il login"
        ).then(() => logoutFunction());
      }
      if (e == "525") {
        store.commit("setManutenzione", true);
        router.push("/manutenzione");
      }
      return {
        ...e.response,
      };
    });
};

let controller;

export const abortRequest = () => {
  controller.abort("Ricerca utente");
};

export const requestAppGet = async (
  endpoint: string,
  params: Record<string, unknown>
) => {
  return dbManagApp
    .get(endpoint, params)
    .then((res) => {
      store.commit("setManutenzione", false);
      if (res.status == 401) {
        // msalInstance.logoutRedirect();
        throw new Error("401");
      }
      if (res.status == 525) {
        // msalInstance.logoutRedirect();
        throw new Error("525");
      }
      return {
        ...res,
      };
    })
    .catch((e) => {
      const { logoutFunction } = useSSOFunction();
      if (e == "401") {
        alertFailed(
          "Sessione Scaduta. Per continuare, effettuare nuovamente il login"
        ).then(() => logoutFunction());
      } else if (e == "525") {
        store.commit("setManutenzione", true);
        router.push("/manutenzione");
      } else {
        alertFailed(
          e.response.data.message
            ? e.response.data.message
            : "Attenzione! Si è verificato un errore. Riprovare più tardi"
        );
      }

      return {
        ...e.response,
      };
    });
};

export const sendFile = axios.create({
  method: "post",
  baseURL: `${baseUrlApiApp}`,
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + localStorage.getItem("tokenAdb2c"),
  },
  // onUploadProgress: function (progressEvent) {
  //   var percentCompleted = Math.round(
  //     (progressEvent.loaded * 100) / progressEvent.total
  //   );
  // },

  /* other custom settings */
});
sendFile.interceptors.request.use((config) => {
  const token = localStorage.getItem("tokenAdb2c");
  config.headers["Authorization"] = "Bearer " + token;
  return config;
});
export const fileSender = async (bodyFormData, apiLink) => {
  return sendFile
    .post(apiLink, bodyFormData)
    .then((res) => {
      store.commit("setManutenzione", false);
      if (res.status == 401) {
        // msalInstance.logoutRedirect();
        throw new Error("401");
      }
      if (res.status == 525) {
        // msalInstance.logoutRedirect();
        throw new Error("525");
      }
      return {
        ...res,
      };
    })
    .catch((e) => {
      const { logoutFunction } = useSSOFunction();
      if (e == "401") {
        alertFailed(
          "Sessione Scaduta. Per continuare, effettuare nuovamente il login"
        ).then(() => logoutFunction());
      } else if (e == "525") {
        store.commit("setManutenzione", true);
        router.push("/manutenzione");
      } else {
        alertFailed(
          e.response.data.message
            ? e.response.data.message
            : "Attenzione! Si è verificato un errore. Riprovare più tardi"
        );
      }
      return {
        ...e.response,
      };
    });
};

const dbManagControlloTokenFCH = axios.create({
  baseURL: baseUrlApiApp,
  // headers: {
  //   Authorization: "Bearer " + localStorage.getItem("id_token"),
  // },
  /* other custom settings */
});

export const requests = {
  dbManag: dbManagApp,
  baseUrlApiApp,
  dbManagControlloTokenFCH,
};
