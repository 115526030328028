import { createApp } from "vue";
import App from "./App.vue";
/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import router from "./router";
import store from "./store";
// import i18n from "@/core/plugins/i18n";

//imports for app initialization
// import ApiService from "@/core/services/ApiService";
// import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import "@/core/plugins/prismjs";
import "bootstrap";

import { msalPlugin } from "@/plugins/msalPlugin";
import {
  msalInstance,
  loginRequest,
  silentTokenRequest,
  msalInstanceReset,
  resetPasswordRequest,
} from "./authConfig";
import { AuthenticationResult, EventType } from "@azure/msal-browser";
import { CustomNavigationClient } from "@/router/NavigationClient";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import LoaderVue from "@/components/LoaderInsidePage.vue";
import { checkTokenFromFCH } from "@/requests/checkTokenFromFCH";
import useSSOFunction from "@/composables/composableGestioneSSO";
const { loginFunction } = useSSOFunction();
const navigationClient = new CustomNavigationClient(router);
msalInstance.setNavigationClient(navigationClient);

const url = window.location.href;
let utenteFCH = false;
let puoEntrareUtenteFCHStatus = 0;
const controlloTokenFCH = async () => {
  const queryString = window.location.search;

  const urlParams = new URLSearchParams(queryString);
  const FCHT = urlParams.get(process.env.VUE_APP_TEXT ?? "");

  puoEntrareUtenteFCHStatus = await checkTokenFromFCH(FCHT);

  localStorage.setItem("utenteFCH", utenteFCH ? "1" : "0");
  localStorage.setItem("FCHT", FCHT as string);
  localStorage.setItem(
    "puoEntrareUtenteFCHStatus",
    puoEntrareUtenteFCHStatus.toString()
  );
};

const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);

  msalInstance.acquireTokenSilent(silentTokenRequest).then((userInfo) => {
    // store.commit("resetAuthModule");
    localStorage.setItem("tokenAdb2c", userInfo.idToken);
    store.dispatch("Adb2cObjectHandle", {
      token: userInfo.idToken,
      user: msalInstance.getActiveAccount(),
    });
  });
}

if (url.includes(process.env.VUE_APP_TEXT ?? "")) {
  const expires = new Date(
    new Date().setFullYear(new Date().getFullYear() + 1)
  );
  document.cookie = `changed_credentials=true; expires=${expires}; Path=/`;
  utenteFCH = true;
  controlloTokenFCH();
}

msalInstance.addEventCallback(async (event: any) => {
  if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
    localStorage.setItem("tokenAdb2c", event.payload.idToken);
    localStorage.setItem("guid", event.payload.uniqueId);
    await loginFunction();
    store.dispatch("Adb2cObjectHandle", {
      token: event.payload.idToken,
      user: msalInstance.getActiveAccount(),
    });
    // await store.dispatch("getMenu", { id_macrocategoria: 1 });
    // if (
    //   localStorage.getItem("utenteFCH") == "0" ||
    //   !localStorage.getItem("utenteFCH")
    // ) {
    //   // controllare se token di societa
    //   // se si, alert per andare su fch
    //   // altrimenti vai avanti
    //   const isValidTokenStatus = await checkTokenFromFCH(
    //     localStorage.getItem("tokenAdb2c")
    //   );
    //   if (isValidTokenStatus == 401) {
    //     router.push("/404");
    //     localStorage.setItem("goToFch", "1");
    //     //           "Dopo il lancio della nuovissima <br> <strong>FITP CONNECT HUB</strong>, sarà necessario effettuare l'autenticazione tramite questa piattaforma per accedere al Management.<br>Chiudendo il popup, verrai automaticamente reindirizzato alla nuova piattaforma.<br> Ti ricordiamo di utilizzare le medesime credenziali del management"
    //     alertFailed(
    //       "L’accesso al sistema può avvenire solo tramite <strong>FITP CONNECT HUB</strong>.<br>Chiudendo il pop-up sarai automaticamente reindirizzato alla pagina di login.<br>Ti ricordiamo che sarà necessario utilizzare le medesime credenziali del Management"
    //     ).then(async () => {
    //       await logout(true);
    //     });
    //   }
    //   return;
    // } else {
    //   // controllare se puo entrare
    //   if (localStorage.getItem("puoEntrareUtenteFCHStatus") == "200") {
    //     return;
    //   } else {
    //     await logout(true);
    //   }
    // }
    // const isValidTokenStatus = await checkTokenFromFCH();
    // if (isValidTokenStatus == 401) {
    //   // router.push("/404");
    //   localStorage.setItem("goToFch", "1");
    //   // alertFailed(
    //   //   "Dopo il lancio della nuovissima <br> <strong>FITP | CONNECT HUB</strong>, sarà necessario effettuare l'autenticazione tramite questa piattaforma per accedere al Management.<br>Chiudendo il popup, verrai automaticamente reindirizzato alla nuova <strong>FITP | CONNECT HUB</strong>"
    //   // ).then(async () => {
    //   //   // await logout(true);
    //   // });
    // }
  }
  if (event.eventType === EventType.LOGIN_FAILURE) {
    if (event.error.errorMessage.includes("AADB2C90118")) {
      msalInstanceReset.loginRedirect(resetPasswordRequest);
    } else store.dispatch("Logout");
  }
  if (
    event.eventType === EventType.ACQUIRE_TOKEN_FAILURE ||
    event.eventType === EventType.SSO_SILENT_FAILURE
  ) {
    store.dispatch("Logout");
  }

  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
    await msalInstance
      .acquireTokenSilent(loginRequest)
      .then(async (userInfo: any) => {
        // await BulkDelete({
        //   id_Utente: userInfo.idTokenClaims.sub,
        //   token: userInfo.idToken,
        // });
        // store.commit("resetAuthModule");
        localStorage.setItem("tokenAdb2c", userInfo.idToken);
        localStorage.setItem("guid", userInfo.uniqueId);
        await loginFunction();
        store.dispatch("Adb2cObjectHandle", {
          token: userInfo.idToken,
          user: msalInstance.getActiveAccount(),
        });
        // await store.dispatch("getMenu", { id_macrocategoria: 1 });
        if (store.getters.redirect_comunicazioni) {
          router.push("/comunicazioni");
        } else {
          router.push("/gestione-tornei/gestione-manifestazioni");
        }
      });
  }
});

const app = createApp(App);
app.use(store);
app.use(router);
app.use(msalPlugin, msalInstance);

// ApiService.init(app);
// initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

//app.use(i18n);
app.use(ElementPlus, { size: "small", zIndex: 3000 });
app.component("Loading", Loading).component("Loader", LoaderVue);
router.isReady().then(async () => {
  app.mount("#app");
});
