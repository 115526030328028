import { requestAppPost } from "@/db/dbManag";
import { API } from "@/enums/costanti";
import { msalInstance } from "@/authConfig";
import { alertFailed } from "@/composables/swAlert";
import router from "@/router/index.ts";

const getDefaultState = () => {
  return {
    loadedMenu: false,
    statusMenu: 0,
    menu: [],
    macroarea: "",
    configurazioni: [],
    ruoli: [],
    comandiEnabled: [],
    pagine_abilitate: [],
    is_valid: 0,
    id_persona: null,
    id_societa: null,
    societa_nome: null,
    utente: [],
    isManutenzione: false,
    redirect_comunicazioni: null,
    nonAutorizzato: false,
  };
};

const state = getDefaultState();

const getters = {
  configurazioni: (state) => state.configurazioni,
  macroarea: (state) => state.macroarea,
  menu: (state) => state.menu,
  loadedMenu: (state) => state.loadedMenu,
  statusMenu: (state) => state.statusMenu,
  nonAutorizzato: (state) => state.nonAutorizzato,
  getConfigurazione: (state) => (stateName) => {
    return state[stateName];
  },
  ruoli: (state) => state.ruoli,
  comandiEnabled: (state) => state.comandiEnabled,
  pagine_abilitate: (state) => state.pagine_abilitate,
  prima_pagina_disponibile: (state) => state.menu[0],
  is_valid: (state) => state.is_valid,
  id_persona: (state) => state.id_persona,
  id_societa: (state) => state.id_societa,
  societa_nome: (state) => state.societa_nome,
  utente: (state) => state.utente,
  isManutenzione: (state) => state.isManutenzione,
  comunicazione: (state) => state.comunicazione,
  redirect_comunicazioni: (state) => state.redirect_comunicazioni,
};
const actions = {
  getMenu: async (storeObj, payload) => {
    storeObj.commit("resetMenu");
    const res = await requestAppPost(API.MENU_GET, payload).then((res) => {
      if (res.status != 200) {
        if (res.status == 525) {
          return res;
        }
        msalInstance.logoutRedirect();
      }
      return res;
    });

    console.log(res);
    if (res.status == 525) {
      return;
    }

    storeObj.commit("setMenu", res);

    // state.comandiEnabled.
  },

  getComunicazione: async (storeObj, payload) => {
    // storeObj.commit("resetMenu");
    const res = await requestAppPost(API.COMUNICAZIONI_GET, payload).then(
      (res) => {
        return res;
      }
    );

    storeObj.commit("setComunicazione", res);

    // state.comandiEnabled.
  },
};
const mutations = {
  setMenu: (state, res) => {
    state.statusMenu = res.status;
    state.configurazioni = res.data.configurazioni;
    state.menu = res.data.menu;
    state.ruoli = res.data.ruoli;
    // state.ruoli = [2];
    state.comandiEnabled = res.data.comandi_enabled;
    state.pagine_abilitate = res.data.pagine_abilitate;
    state.is_valid = res.data.is_valid;
    if (!state.is_valid) {
      alertFailed(
        res.data.errore_affiliazione
          ? res.data.errore_affiliazione
          : "Errore nel login"
      ).then(() => {
        msalInstance.logoutRedirect();
      });
      return;
    }
    state.utente = res.data.utente;
    state.id_persona = res.data.utente[0].id_persona;
    state.id_societa = res.data.utente[0].id_societa;
    state.societa_nome = res.data.utente[0].societa;
    state.redirect_comunicazioni = res.data.redirect_comunicazioni;
    const autorizzatoVisitarePagina = state.pagine_abilitate.some((pagina) => {
      return pagina
        .replace(/\/:[^/]+/g, "")
        .startsWith(`/${router.currentRoute.value.fullPath.split("/")[1]}`);
    });
    console.log(router, router.currentRoute.value.fullPath.split("/")[1]);
    state.nonAutorizzato = autorizzatoVisitarePagina;

    state.configurazioni.forEach(({ sezione, costanti }) => {
      state[sezione] = {};
      costanti.forEach(({ chiave, valore }) => {
        state[sezione][chiave] = valore;
      });
    });

    if (
      !router.currentRoute.value.fullPath.includes(
        process.env.VUE_APP_TEXT ?? ""
      ) &&
      !autorizzatoVisitarePagina
    ) {
      console.log("non autorizzato");
      setTimeout(() => {
        router.push("/non-autorizzato");
      }, 0);
    }

    state.loadedMenu = true;
  },
  resetMenu: (state) => {
    Object.assign(state, getDefaultState());
  },
  setManutenzione: (state, val) => {
    state.isManutenzione = val;
  },
  setRedirectComunicazioni: (state, val) => {
    state.redirect_comunicazioni = val;
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
};
